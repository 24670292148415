const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { GetInTouchForm } = require('../../react-components/GetInTouchForm/GetInTouchForm');

/**
 * get GetInTouchForm component
 * @param {string} profileType profile type
 * @param {string} name full profile name
 * @param {string} firstName first profile name
 * @param {boolean} isProd if the environment production or not
 * @returns Server side ready react component
 */
const setGetInTouchForm = ({
	profileType = '',
	name = '',
	firstName = '',
	isProd = false,
} = {}) => useReactComponent(GetInTouchForm, {
	profileType,
	profileName: name,
	firstName,
	isProd,
});

module.exports = {
	setGetInTouchForm,
};
