const React = require('react');
const CarouselPagination = require('@forbes/fbs-components/dist/cjs/components/Carousel/CarouselPagination').default;
const { useReactComponent } = require('../../../../app/shared/componentLibraryService');

const getCarouselPagination = (carouselItems) => {
	const carouselConfig = {
		loop: true,
		speed: 500,
		spaceBetween: 16,
		showControls: true,
		paginationProps: {
			clickable: true,
			horizontalClass: 'profile-carousel__bullet',
		},
		controlProps: {
			className: 'profile-carousel__arrow',
			classNamePrev: 'profile-carousel__prev',
			size: 'small',
		},
	};

	return useReactComponent(
		CarouselPagination,
		carouselConfig,
		carouselItems.map((item, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div className="carousel-content-wrapper" key={index}>
				<img
					className="carousel-image"
					src={item.src}
					alt={`Carousel ${index + 1}`}
				/>
				<p className="carousel-caption">{item.caption}</p>
			</div>
		)),
	);
};

const hydrateCarouselPagination = () => {
	const carouselContainer = document.querySelector('.listuser-carousel-block');
	const { carouselItems } = window.forbes?.['simple-site'] || {};

	if (carouselContainer && carouselItems) {
		const { component, hydrate } = getCarouselPagination(carouselItems);

		carouselContainer.innerHTML = component;

		hydrate(carouselContainer);
	}
};

hydrateCarouselPagination();

module.exports = {
	getCarouselPagination,
	hydrateCarouselPagination,
};
