document.addEventListener('DOMContentLoaded', () => {
	const getInTouchOpen = document.querySelectorAll('.listuser-get-in-touch--button');
	const inlineGetInTouchForm = document.querySelector('.get-in-touch__button');

	getInTouchOpen.forEach((button) => {
		button.addEventListener('click', () => {
			inlineGetInTouchForm.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		});
	});
});
