const { setGetInTouchForm } = require('../profile/react/getInTouchForm');

/**
 * Hydrates the GetInTouch component after being rendered from the server.
 */
const hydrateGetInTouchForm = () => {
	const getInTouchContainer = document.querySelector('.get-in-touch');
	const {
		profileType = '',
		name = '',
		firstName = '',
		isProd = false,
	} = window.forbes?.['simple-site'] || {};

	if (getInTouchContainer) {
		const { hydrate } = setGetInTouchForm({
			profileType,
			name,
			firstName,
			isProd,
		});

		hydrate(getInTouchContainer);
	}
};

hydrateGetInTouchForm();
