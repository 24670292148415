const React = require('react');

const {
	useState,
	useEffect,
	useCallback,
	useRef,
} = React;

const { default: InputField } = require('@forbes/fbs-components/dist/cjs/components/InputField/InputField');
const { default: CustomDropdown } = require('@forbes/fbs-components/dist/cjs/components/CustomDropdown/CustomDropdown');
const { default: ButtonCTA } = require('@forbes/fbs-components/dist/cjs/components/Button/ButtonCTA');
const { close } = require('@forbes/fbs-icons');
const { PropTypes } = require('prop-types');
const { default: ReCAPTCHA } = require('react-google-recaptcha');
const { validateDataViaRegex } = require('@forbes/fbs-profiles');
const { fireGAEvent } = require('../../shared/trackingUtils');

/**
 * Sets the options for the get in touch form based on profile type
 * @param {String} profileType the type of the profile
 * @returns {string[]} An array of options for the get in touch form
 */
const setGetInTouchFormOptions = (profileType) => {
	const commonGetInTouchOptions = [
		'Partnership opportunity', 'Interested in your services', 'Funding or investment opportunity',
		'Employment at your company', 'Requesting your pitchbook', 'Other',
	];
	const commonProfessionalServicesGetInTouchOptions = [
		'Partnership opportunity', 'Interested in your services', 'Employment at your company',
		'Media & public relations inquiry', 'Other'];

	const getInTouchOptionsByType = {
		wealthAdvisor: commonGetInTouchOptions,
		FSP: commonGetInTouchOptions,
		next1000: commonGetInTouchOptions,
		lawyers: commonProfessionalServicesGetInTouchOptions,
		CPA: commonProfessionalServicesGetInTouchOptions,
		architectPerson: commonProfessionalServicesGetInTouchOptions,
		architectFirm: commonProfessionalServicesGetInTouchOptions,
	};

	return getInTouchOptionsByType[profileType] || [];
};

const GetInTouchForm = ({
	profileType, profileName, firstName, isProd,
} = {}) => {
	const [formInfo, setFormInfo] = useState({ email: '', reason: null });
	const [errors, setErrors] = useState({});
	const [dropdownKey, setDropdownKey] = useState(0); // @TODO: Remove it when CustomDropdown is fixed (not resetting the UI)
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const recaptchaRef = useRef();
	const reCaptchaSiteKey = isProd
		? '6LeLC2wpAAAAAHxdEQ59hxseRouCtGrCo22PUjuu'
		: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

	const reCaptchaExecute = useCallback(async () => {
		let token = null;
		if (recaptchaRef.current) {
			try {
				token = await recaptchaRef.current.executeAsync();
				recaptchaRef.current.reset();
			} catch (error) {
				console.error('Error executing reCAPTCHA: ', error);
			}
		}

		return token;
	}, []);

	const handleInputChange = (e) => {
		const { value, name } = e.currentTarget;

		setFormInfo({ ...formInfo, [name]: value });

		if (name === 'email') {
			const isValidEmail = validateDataViaRegex('email', value);

			setErrors({ ...errors, formError: '', email: isValidEmail ? '' : 'Please enter a valid email address.' });
		} else {
			setErrors({ ...errors, formError: '' });
		}
	};

	const handleDropdownChange = (option) => {
		setFormInfo({ ...formInfo, reason: option.value });
		setErrors({ ...errors, formError: '', reason: '' });
	};

	const validateForm = () => {
		const isEmailValid = !!formInfo.email;
		const isReasonValid = !!formInfo.reason;

		return { isEmailValid, isReasonValid };
	};

	const sendFormRequest = async (requestData) => {
		try {
			const response = await fetch('/simple-data/submit-profile-form', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestData),
			});

			return response.json();
		} catch (error) {
			console.error('Error submitting the form:', error);

			return { success: false };
		}
	};

	const handleFormSuccess = () => {
		setFormInfo({ reason: '', email: '', name: '' });
		setDropdownKey((prevKey) => prevKey + 1);
		setShowSuccessModal(true);
		fireGAEvent({
			eventAction: 'Profile Click',
			eventLabel: 'Get in Touch-Send Introduction',
		});
	};

	const handleFormError = () => {
		setErrors({ ...errors, formError: 'Error submitting the form. Please try again.' });
	};

	const handleSendingIntroduction = async () => {
		const { isEmailValid, isReasonValid } = validateForm(formInfo);

		if (!isEmailValid || !isReasonValid) {
			setErrors({
				email: isEmailValid ? '' : 'Please enter a valid email address.',
				reason: isReasonValid ? '' : 'Please select a reason.',
			});

			return;
		}

		setErrors((prevErrors) => ({ ...prevErrors, formError: '' }));

		const executionToken = await reCaptchaExecute();
		if (!executionToken) {
			handleFormError();
			return;
		}

		const { naturalId } = window.forbes?.['simple-site'] || {};
		const requestData = {
			...formInfo,
			naturalId,
			captchaResponse: executionToken,
		};

		const responseData = await sendFormRequest(requestData);

		if (responseData.success === true || responseData.error === false) {
			handleFormSuccess();
		} else {
			handleFormError();
		}
	};

	useEffect(() => {
		document.body.classList.toggle('modal-open', showSuccessModal);

		return () => {
			document.body.classList.remove('modal-open');
		};
	}, [showSuccessModal]);

	return (
		<div className="get-in-touch__wrapper">
			<span className="get-in-touch__title">Request An Introduction</span>
			<InputField
				helperText="Please enter a valid email address."
				inputProps={{
					id: 'email',
					type: 'text',
					onChange: handleInputChange,
					value: formInfo.email,
					name: 'email',
				}}
				label="Email"
				state={errors.email ? 'error' : 'valid'}
				errorMessage={errors.email}
			/>
			<CustomDropdown
				key={dropdownKey}
				options={setGetInTouchFormOptions(profileType).map((reason) => ({ displayText: reason, key: reason, value: reason }))}
				label="Reason For Getting in Touch"
				name="reason"
				onChange={handleDropdownChange}
				error={errors.reason}
				value={formInfo.reason}
			/>
			<InputField
				inputProps={{
					id: 'name',
					type: 'text',
					onChange: handleInputChange,
					value: formInfo.name,
					name: 'name',
				}}
				label="Name (Optional)"
			/>
			<div className="get-in-touch__form-submit">
				{errors.formError && <span className="form-submit-error">{errors.formError}</span>}
				<ButtonCTA className="get-in-touch__button" variant="secondary" onClick={handleSendingIntroduction}>Send Introduction</ButtonCTA>
			</div>
			{showSuccessModal && (
				<div className="get-in-touch__modal-overlay">
					<div className="get-in-touch__modal-content">
						<button className="modal-cancel-icon" type="button" onClick={() => setShowSuccessModal(false)} aria-label="close-modal" data-testid="close-modal" dangerouslySetInnerHTML={{ __html: close }} />
						<p className="successful-message-title">Your Inquiry Was Sent Successfully.</p>
						<span className="successful-message-text">{`Your request for an introduction with ${profileName} has been successfully submitted.`}</span>
						<span className="successful-message-text">{`Look out for a follow-up from ${firstName || profileName} in your inbox.`}</span>
					</div>
				</div>
			)}
			<div>
				<ReCAPTCHA
					sitekey={reCaptchaSiteKey}
					ref={recaptchaRef}
					size="invisible"
					data-testid="recaptcha"
					className="invisible-recaptcha"
				/>
				<p className="get-in-touch__recaptcha-text">
					Forbes is protected by reCAPTCHA, and the
					{' '}
					<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google Privacy Policy</a>
					{' '}
					and
					{' '}
					<a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a>
					{' '}
					apply.
				</p>
			</div>
		</div>
	);
};

GetInTouchForm.propTypes = {
	profileType: PropTypes.string,
	firstName: PropTypes.string,
	profileName: PropTypes.string,
	isProd: PropTypes.bool,
};

module.exports = { GetInTouchForm };
